import React from "react"
import Img from "gatsby-image"
import SpeakerInfoTile from "./speakerInfoTile"
import ImageSlider from "./imageSlider"
import * as twoColumnImageStyles from "./twoColumnImageComponent.module.scss"

const getHeading = (title, subtitle) => {
    return(
        <div className={twoColumnImageStyles.titles}>
            {title && <h1 className="title is-1">{title}</h1>}
            {subtitle &&<p className={twoColumnImageStyles.titleContent}>{subtitle}</p>}
        </div>
    )
}

const getImages = (images) => {
    if(!images || images.length === 0) {
        return (
            <div></div>
        )
    }
    if (images.length > 1) {
        return (
            <div className={twoColumnImageStyles.sliderWrapper}>
                <ImageSlider images={images} />
            </div>
        )
    }
    return (
        <div className={twoColumnImageStyles.imageWrapper}>
            <Img style={{width: "100%", height: "100%"}} imgStyle={{ objectFit: "contain" }}  fluid={images[0].childImageSharp.fluid} />
        </div>
    )
}

const getSpeakerBox = (speakers) => {
    return(
        <div>
            {speakers && speakers.map((speaker, i) => {
                return(
                    <div className={`pt-2`} key={i}>
                        <SpeakerInfoTile isUrl={false} isHTML={false} 
                                image={speaker.imgUrl} text={speaker.description} name={speaker.name}
                                company={speaker.company}/>
                    </div>
                )
            })}
        </div>
    )
}

const getContent = (children) => {
    return (
        <div>
            {children}
        </div>
    )
}

function TwoColumnComponent(props) {
    const { title,  children, subtitle, speakers, images } = props
    return(
        <div className={twoColumnImageStyles.content}>
            <div className={`columns ${twoColumnImageStyles.isDesktop}`}>
                <div className={`column is-6 pr-5`}>
                    {getHeading(title, subtitle)}
                    {getImages(images)}
                    {getSpeakerBox(speakers)}
                </div>
                <div className="column is-6">{getContent(children)}</div>
            </div>  
            <div className={`columns ${twoColumnImageStyles.isMobile}`}>
                <div className={`column is-12`}>
                    {getHeading(title, subtitle)}
                    {getImages(images)}
                    {getContent(children)}
                    {getSpeakerBox(speakers)}
                </div>
            </div>
        </div>
    )
}

export default TwoColumnComponent