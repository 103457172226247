import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContentContainer from "../components/contentContainer"
import TwoColumnImageComponent from "../components/twoColumnImageComponent"

export const query = graphql`
    query($slug: String!) {
        markdownRemark(fields: {
            slug: {
                eq: $slug
            }
        }) {
            html
            fileAbsolutePath
            frontmatter {
                title
                subtitle
                date
                seoTitle
                seoDescription
                images {
                    childImageSharp {
                        fluid {
                        ...GatsbyImageSharpFluid
                        }
                    }    
                }
                speakers {
                    company
                    description
                    name
                    imgUrl {
                        childImageSharp {
                            fixed(width: 800) {
                            ...GatsbyImageSharpFixed
                            }
                        }
                    }
                }
            }
        }
    }
`



const TwoColumnPost = (props) => {
    const post = props.data.markdownRemark
    const siteTitle = "post"
    return (
        <Layout location={props.location} title={siteTitle}>
        <SEO  title={post.frontmatter.seoTitle || post.frontmatter.title} description={post.frontmatter.seoDescription} />
        <div className="content">
          <ContentContainer paddingMobile paddingTop>
            <TwoColumnImageComponent title={post.frontmatter.title} subtitle={post.frontmatter.subtitle} 
                                    speakers ={post.frontmatter.speakers} images={post.frontmatter.images}>
                          <div dangerouslySetInnerHTML={{ __html: post.html }}></div>
            </TwoColumnImageComponent>
          </ContentContainer>
        </div>
      </Layout>
      )
}

export default TwoColumnPost