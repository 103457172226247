import React, { useState } from "react"
import Img from "gatsby-image"
import * as imageSliderStyles from "./imageSlider.module.scss"
import { FaChevronCircleRight } from "@react-icons/all-files/fa/FaChevronCircleRight"
import { FaChevronCircleLeft } from "@react-icons/all-files/fa/FaChevronCircleLeft"

const ImageSlider = ({ images }) => {
  const [current, setCurrent] = useState(0)
  const length = images.length

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1)
  }

  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1)
  }

  if (!Array.isArray(images) || images.length <= 0) {
    return null
  }

  return (
    <div className={imageSliderStyles.slider}>
      <FaChevronCircleLeft className={imageSliderStyles.leftArrow} onClick={prevSlide} />
      <FaChevronCircleRight className={imageSliderStyles.rightArrow} onClick={nextSlide} />
      {images.map((slide, index) => {
        return (
          <div
            className={`${imageSliderStyles.slide} ${index === current ? imageSliderStyles.active : ""}`}
            key={index}
          >
            {index === current && (
              <Img style={{ width: "100%", height: "100%" }} className={imageSliderStyles.image}
                   imgStyle={{ objectFit: "contain" }} fluid={slide.childImageSharp.fluid} />
            )}
          </div>
        )
      })}
    </div>
  )
}

export default ImageSlider
