import React from "react"
import Img from "gatsby-image"
import * as speakerInfoStyles from "./speakerInfoTile.module.scss"

function SpeakerInfoTile(props) {
    const { image, text, isUrl, isHTML, name, company } = props;
    const hasImage = !!image
    return(
        <div className={`${speakerInfoStyles.speakerInfos}`}>
            {hasImage && <div className={speakerInfoStyles.speakerImageWrapper}>
                {!isUrl && image &&
                    <div className={speakerInfoStyles.speakerImage} >
                        <Img style={{ height: "100px", width: "100px"}} 
                            imgStyle={{ objectFit: "contain" }} fixed={image.childImageSharp.fixed} alt=""/>
                    </div>
                }
                {isUrl && image &&
                    <div className={speakerInfoStyles.speakerImage}>
                        <img style={{ height: "100px", width: "100px"}} src={image} alt=""/>
                    </div>
                }
            </div>}
            <div className={`${hasImage ? speakerInfoStyles.speakerInfoWithImage : speakerInfoStyles.speakerInfoWithoutImage} ${speakerInfoStyles.speakerInfoText}`}>
                <div className={speakerInfoStyles.speakerHeading}>
                    <p className={speakerInfoStyles.speakerDetails}>{name} &nbsp;</p>
                    <span>
                        {company && 
                            <p className={speakerInfoStyles.speakerDetails}>&#183; &nbsp;{company}</p>
                        }
                    </span>
                </div>
                <div>
                    {isHTML && <div dangerouslySetInnerHTML={{ __html: text }} />}
                    {!isHTML && <p>{text}</p>}
                </div>
            </div>
        </div>
    )
}

export default SpeakerInfoTile